<template>
  <div class="create-content-main">
    <div class="setting-menu background-content-admin">
      <!-- <div class="mb-2 justify-content-between row">
        <h2 class="col-12 col-md-8 font-title font-weight-bold my-auto">
          フッターメニュー管理
        </h2>
        <div class="col-12 col-md-4 tex-left text-md-right text-sm-left">
          <b-button
            class="button-back-dashboard ml-0"
            v-on:click="gotoDashboard()"
            >戻る</b-button
          >
        </div>
      </div> -->
      <div class="row mx-3">
        <h2
          class="col-md-8 col-12 font-title font-weight-bold pl-0 my-auto header-font"
        >
          フッターメニュー管理
        </h2>
        <div class="col-md-4 col-12 px-0">
          <b-button
            class="button-back-dashboard float-md-right back-btn"
            v-on:click="gotoDashboard()"
          >
            戻る
          </b-button>
        </div>
      </div>
      <!-- Footer Setting -->
      <div class="content-setting-header pt-4">
        <h4 class="mb-3 font-title-default label-title">表示メニュー</h4>
        <div class="container" v-if="listFooter && listFooter.length">
          <div
            class="row my-2"
            v-for="(value, index) in listFooter"
            :key="index"
          >
            <div class="col-2 col-md-1">
              <b-button
                variant="dark"
                class="rounded-circle custome-btn-sub"
                style="border-radius: 50% !important"
                v-on:click="subFooter(index)"
                ><b-icon icon="dash" scale="2"
              /></b-button>
            </div>
            <div class="col-10 col-md-11">
              <b-form-select
                class="w-100 custome-text-select"
                id="select-cate"
                v-model="value.page_title"
                tag-placeholder="これを新しいフッターとして追加します"
                :options="listPageFooterOptions"
              ></b-form-select>
            </div>
            <div
              class="col-12 mx-auto justify-content-center mt-2 setting-slag"
            >
              <div class="setting-show-slag d-flex justify-content-center">
                <p class="my-auto mr-3">表示設定</p>
                <div class="custom-select-2 custome-multi-slag">
                  <multiselect
                    v-model="value.slag_display"
                    tag-placeholder="スラグを追加"
                    placeholder=""
                    label="slag_name"
                    track-by="slag_name"
                    :options="listSlagOptions"
                    :multiple="true"
                    :taggable="false"
                    :showNoResults="false"
                    :max-height="200"
                    select-label="選択"
                    selected-label=""
                    deselect-label="削除"
                  >
                    <template v-slot:no-options> リストは空です </template>
                  </multiselect>
                </div>
              </div>
              <div class="setting-hidden-slag d-flex justify-content-center">
                <p class="my-auto mr-3">非表示設定</p>
                <div class="custom-select-2 custome-multi-slag">
                  <multiselect
                    v-model="value.slag_non_display"
                    tag-placeholder="スラグを追加"
                    placeholder=""
                    label="slag_name"
                    track-by="slag_name"
                    :options="listSlagOptions"
                    :multiple="true"
                    :taggable="false"
                    :showNoResults="false"
                    :max-height="200"
                    select-label="選択"
                    selected-label=""
                    deselect-label="削除"
                  >
                    <template v-slot:no-options> リストは空です </template>
                  </multiselect>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center my-4">
            <b-button
              variant="success"
              class="rounded-circle custome-btn-add"
              style="border-radius: 50% !important"
              v-on:click="addFooter()"
              ><b-icon class="sidebar-icon" icon="plus" font-scale="2"
            /></b-button>
          </div>
          <div class="d-flex justify-content-center">
            <b-button
              class="btn-color"
              type="submit"
              :disabled="isLoading"
              variant="success"
              v-on:click.prevent="updateFooter()"
            >
              <b-spinner v-if="isLoading" small></b-spinner>
              <span>編集内容を反映する</span>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import no_image from "@/assets/img/no-image.jpg";
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
import { Routes } from "../../utils/routes";
export default {
  name: "footerSetting",
  components: {},
  data() {
    return {
      isLoading: false,
      no_image: no_image,
      active_el: "",
      listPageFooterOptions: [],
      selectedValue: null,
      listFP: [],
      listSlagOptions: [],
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    };
  },
  created() {
    const requestFP = {
      shop_id: this.shop_id,
      type: this.type,
    };
    this.getListFixed(requestFP);
    const request = {
      shop_id: this.shop_id,
    };
    this.getFooterSetting(request);
    this.getALLlistSlag(request);
  },
  computed: {
    ...mapGetters([
      "listFooter",
      "listFixedPage",
      "listSlag",
      "message",
      "success",
      "error",
    ]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listFixedPage() {
      this.listFP = this.listFixedPage;
      if (this.listFP.length) {
        this.listFP.forEach((item) => {
          var fixedPage = {
            text:
              item && item.title && item.title.length > Constants.MAX_LENGTH
                ? item.title.substr(0, Constants.MAX_LENGTH) + "..."
                : item.title,
            value: item.title,
          };
          this.listPageFooterOptions.push(fixedPage);
        });
      } else {
        this.listPageFooterOptions = [
          { text: "単発ページはありません", value: "", disabled: true },
        ];
      }
    },
    listFooter() {
      if (this.listFooter && this.listFooter.length == 0) {
        this.listFooter.push({
          page_title: "",
          shop_id: this.shop_id,
          url: "",
          slag_display: [],
          slag_non_display: [],
        });
      } else {
        this.listFooter.forEach((footer) => {
          if (footer.slag_display && footer.slag_display.length > 0) {
            footer.slag_display.forEach((item) => {
              item.slag_name = item.slag_name
                ? item.slag_name
                : item.slag_name_en;
            });
          }
          if (footer.slag_non_display && footer.slag_non_display.length > 0) {
            footer.slag_non_display.forEach((item) => {
              item.slag_name = item.slag_name
                ? item.slag_name
                : item.slag_name_en;
            });
          }
        });
      }
    },
    listSlag() {
      this.listSlagOptions = this.listSlag.map((item) => ({
        id: item.id,
        slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
        slag_name_en: item.slag_name_en,
        slag_url: item.url,
        slag_description: item.description,
      }));
    },
  },
  methods: {
    ...mapActions({ getListFixed: "getListFixed" }),
    ...mapActions({ getFooterSetting: "getFooterSetting" }),
    ...mapActions({ getALLlistSlag: "getALLlistSlag" }),
    //footer
    addFooter() {
      this.listFooter.push({
        page_title: "",
        shop_id: this.shop_id,
        url: "",
        slag_display: [],
        slag_non_display: [],
      });
    },
    subFooter(index) {
      this.listFooter.splice(index, 1);
    },
    async updateFooter() {
      this.isLoading = true;
      let shop_id = this.shop_id;
      var formFooter = new FormData();
      formFooter.append("shop_id", shop_id);
      var listFooterUrl = [];
      if (
        this.listFooter &&
        this.listFooter.length == 1 &&
        this.listFooter[0]["page_title"] == ""
      ) {
        const dataReturn = await this.$store.dispatch(
          "updateFooter",
          formFooter
        );
        if (!dataReturn.success) {
          this.isLoading = false;
        } else {
          this.$router.push({
            name: this.$route.params.shopId
              ? "menuManager"
              : "menuManager domain",
          });
        }
      } else if (this.listFooter && this.listFooter.length > 0) {
        for (var i = 0; i < this.listFooter.length; i++) {
          formFooter.append(
            "data[" + i + "][page_title]",
            this.listFooter[i]["page_title"]
          );
          formFooter.append("data[" + i + "][position]", i);
          this.listFP.forEach((item) => {
            var fixedPage = {
              name: item && item.title,
              url: this.$router.resolve({
                name: this.$route.params.shopId
                  ? "staticPage"
                  : "staticPage domain",
                params: { id: item.id },
              }).href,
              id: item && item.id,
            };
            listFooterUrl.push(fixedPage);
          });
          listFooterUrl.forEach((value) => {
            if (value.name == this.listFooter[i]["page_title"]) {
              formFooter.append(
                "data[" + i + "][url]",
                value.url.replace(Routes.SHOP + "/" + shop_id + "/", "")
              );
              formFooter.append("data[" + i + "][fixed_page_id]", value.id);
              return;
            }
          });
          if (
            this.listFooter[i]["slag_display"] &&
            this.listFooter[i]["slag_display"].length > 0
          ) {
            this.listFooter[i]["slag_display"].forEach((itemSlag, index) => {
              formFooter.append(
                "data[" + i + "][slag_display][" + index + "]",
                itemSlag.id
              );
            });
          } else {
            formFooter.append("data[" + i + "][slag_display]", []);
          }
          if (
            this.listFooter[i]["slag_non_display"] &&
            this.listFooter[i]["slag_non_display"].length > 0
          ) {
            this.listFooter[i]["slag_non_display"].forEach(
              (itemSlag, index) => {
                formFooter.append(
                  "data[" + i + "][slag_non_display][" + index + "]",
                  itemSlag.id
                );
              }
            );
          } else {
            formFooter.append("data[" + i + "][slag_non_display]", []);
          }
        }
        const dataReturn = await this.$store.dispatch(
          "updateFooter",
          formFooter
        );
        if (!dataReturn.success) {
          this.isLoading = false;
        } else {
          this.$router.push({
            name: this.$route.params.shopId
              ? "menuManager"
              : "menuManager domain",
          });
        }
      }
    },
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "menuManager" });
      } else {
        this.$router.push({ name: "menuManager domain" });
      }
    },
  },
};
</script>
